//settings
$fa-font-path: "../font";

// Colors //
$black: #000;
$white: #dcdcdc;
$primary-color: #6e190e;
$gray: #232323;

//Fonts
$primary-font: 'SpiritsSharpLight', sans-serif;
