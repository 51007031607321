@import "variables";
// includes
@import "includes/helpers";
@import "includes/normalize";
@import "includes/fonts";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
}

body {
    background-color: $gray;
    background-image: url('../img/bg.png');
    background-size: cover;
    color: $white;
    font-family: $primary-font;
    font-weight: normal;
    font-size: rem(16);
    line-height: 1.2;
    overflow: hidden;
}

a{
    color: inherit;
    transition: .3s all;
    @include hover{
        color: $primary-color;
    }
}

.container{
    max-width: rem(2400);
}

#main{
    padding: rem(15) 0;
    .video-wrapper{
        border-radius: rem(30);
        display: block;
        overflow: hidden;
        position: relative;
        min-height: rem(450);
        height: calc(100vh - 6rem);
        
        @media screen and (max-width: 1050px){
            height: calc(100vh - 15rem);
        }
        video {
            border-radius: rem(30);
            background-color: $black;
            height: 100%;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            top: 0;
            object-position: center;
            object-fit: cover;
        }

        h1{
            position: absolute;
            top: rem(30);
            left: rem(30);
            z-index: 3;
            
            @media screen and (max-width: 1050px){
                display: none
            }
        }
        .center{
            position: absolute;
            padding: rem(10);
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            text-align: center;
            width: 100%;
            img{
                width: 90%;
                max-width: rem(380);
                margin: 0 auto;
            }
            >p{
                display: none;
            }
            p{
                margin: .5em auto;
                text-transform: uppercase;
                font-size: rem(19);
            }
            @media screen and (max-width: 1050px){
                display: flex;
                height: 100%;
                flex-direction: column;
                justify-content: space-between;
                >p{
                    display: block;
                }

                p{
                    margin: .5em auto;
                }
            }
            
        }
    }
}


#footer{
    .container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        @media screen and (max-width: 1050px){
            display: block;
        }
        .social{
            width: 100%;
            max-width: rem(220);
            list-style: none;
            order: 1;
            margin: 0;
            padding: 0;
            
            @media screen and (max-width: 1230px){
                max-width: rem(190);
            }
            
            @media screen and (max-width: 1050px){
                text-align: center;
                margin: rem(10) auto 0;
            }
            li{
                display: inline-block;
                font-size: rem(20);
                margin-right: .7em;
                
                @media screen and (max-width: 1230px){
                    margin-right: .5em;
                }
            }
        }
        .buttons{
            order: 2;
            flex:1;
            display: flex;
            justify-content: space-around;
            text-align: center;
            @media screen and (max-width: 1050px){
                flex-wrap: wrap;
                max-width:rem(320);
                justify-content: space-between;
                width:100%;
                margin: 0 auto;
            }
            a{
                display: inline-block;
                text-decoration: none;
                padding: .7em 2em;
                border: 1px solid $white;
                &.wide{
                    flex: 1;
                }
                &.small{
                    flex-basis: rem(120);
                }
                &+a{
                    margin-left: rem(10);
                }
                @media screen and (max-width: 1230px){
                    padding: .7em;
                }
                
                @media screen and (max-width: 1050px){
                    margin: rem(10) 0 0 !important;
                    &.wide{
                        flex-basis: 100%;
                    }
                    &.small{
                        flex-basis: 48%;
                        order: 3;
                    }
                }
                text-transform: uppercase;
                @include hover{
                    color: $gray;
                    background-color: $white;
                }
            }
        }
        .copyright{
            order: 3;
        }
    }
    .copyright{
        width: 100%;
        max-width: rem(320);
        font-size: rem(9);
        text-transform: uppercase;
        text-align: right;
        @media screen and (max-width: 1050px){
            text-align: center;
            margin: rem(10) auto 0;
        }
        a{
            display: inline-block;
        }
    }
}

.popup{
    position: relative;
    background-image: url('../img/bg.png');
    width: 100%;
    max-width: rem(500);
    margin: rem(30) auto;
    padding: rem(90) rem(50);
    text-align: center;
    
    @media screen and (max-width: 600px){
        padding: rem(80) rem(30) rem(30);
    }

    .mfp-close{
        color: $white;
        border: 1px solid $white;
        opacity: 1;
        transition: .3s all;
        top: rem(20);
        right: rem(20);
        @include hover{
            background-color: $white;
            color: $gray;
        }
    }

    .buttons{
        a{
            
            display: block;
            margin-top: rem(10);
            text-decoration: none;
            padding: .7em 2em;
            border: 1px solid $white;
            @media screen and (max-width: 1230px){
                padding: .7em;
            }
            
            text-transform: uppercase;
            @include hover{
                color: $gray;
                background-color: $white;
            }
        }
    }

    .note{
        font-family: sans-serif;
        padding: 5px;
        font-size: rem(8);
        text-align: center;
        text-transform: uppercase;
    }
}

#tour{
    max-width: rem(950);
    h2{
        font-weight: normal;
        text-transform: uppercase;
        font-size: rem(24);
        margin: 0;
    }
    #events{
        margin: rem(30) auto;
        max-width: rem(600);
        width: 100%;
        text-transform: uppercase;
        font-size: rem(14);
        line-height: 1.3;

        .event{
            display: flex;
            align-items: center;
            text-align: left;
            margin-bottom: .8em;
            @media screen and (max-width: 600px){
                display: block;
                text-align: center;
                .event-links{
                    text-align: center;
                }
            }
        }
        .event-date{
            flex: .5;
        }
        .event-links{
            flex: .5;
            text-align: right;
            a{
                display: inline-block;
                text-decoration: none;
                padding: rem(5) rem(10) rem(3);
                border: 1px solid $white;
                text-transform: uppercase;
                @include hover{
                    color: $gray;
                    background-color: $white;
                }
            }
        }

        .event-location{
            font-family: 'SpiritsSharpRegular', sans-serif;
        }
        .event-location,.event-venue{
            flex: 1;
        }
    }
}
